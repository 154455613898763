'use client'

import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import useDrawerState from '@/hooks/context/useDrawerState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import CompanyInfoStep from '@/components/Admin/Matcher/Steps/CompanyInfoStep'
import FeedbackStep from '@/components/Admin/Matcher/Steps/FeedbackStep'
import ProtectedGroupStep from '@/components/Admin/Matcher/Steps/ProtectedGroupStep'
import SpecificProjectStep from '@/components/Admin/Matcher/Steps/SpecificProjectStep'

import { getAnswer, getDrafts } from '@/service/Chatbot'
import { generateUUID } from '@/utils'

import GrantEditor from './GrantEditor'
import AgentResponseStep from './Steps/AgentResponseStep'
import GrantInfoStep from './Steps/GrantInfoStep'
import SelectGrantStep from './Steps/SelectGrantStep'
import UploadGrantStep from './Steps/UploadGrantStep'
import lottieChatLoadingScreenAnimation from '../../../../public/lottieChatLoadingScreen.json'

import { IQuestion } from '@/types/chatbot'
import { Section } from '@/types/document'

interface MatcherProps {
  setMatcherOpen?: (matcherOpen: boolean) => void
}

const Matcher: React.FC<MatcherProps> = ({ setMatcherOpen }) => {
  const [mode, setMode] = useState<'new' | 'existing'>()
  const [currentStep, setCurrentStep] = useState<number>(-1)
  const [protectedGroup, setProtectedGroup] = useState<string>('')
  const { agents, selectedAgent } = useAgents()
  const { user } = useAuth()
  const [questions, setQuestions] = useState([] as IQuestion[])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { selectedConversation, setSelectedConversation } = useDrawerState()
  const [finishedSteps, setFinishedSteps] = useState(0)
  const [percent, setPercent] = useState(3)
  const [projectDetails, setProjectDetails] = useState<string>()
  const [progressException, setProgressException] = useState(false)
  const [streamMessage, setStreamMessage] = useState(false)
  const [sections, setSections] = useState<Section[]>()
  const [existingGrantDocument, setExistingGrantDocument] = useState<string>()

  useEffect(() => {
    setSelectedConversation(generateUUID())
  }, [])

  useEffect(() => {
    if (finishedSteps === 5) {
      setPercent(100)
      setTimeout(() => {
        setStreamMessage(true)
      }, 1000)
      return
    }

    if (currentStep === 4) {
      // Calculate the target percent with a slight overshoot to indicate progression beyond the current step
      const targetPercent = finishedSteps * 20 + (finishedSteps + 1) * 3 // Adjust the overshoot value as needed
      const interval = setInterval(() => {
        setPercent((p) => {
          // Calculate the difference between the target (with overshoot) and the current progress
          const diff = targetPercent - p
          // Adjust the progress, moving slower as it gets closer to the target
          return p + diff / 15 // Adjust the divisor to control the "slowness"
        })
      }, 100)
      return () => clearInterval(interval)
    }
  }, [finishedSteps])

  useEffect(() => {
    if (questions[1]?.messages[1]?.message) {
      setFinishedSteps(5)
    }
  }, [questions[1]?.messages[1]?.message])

  const gatherGrants = async (values?: { [x: string]: string }) => {
    setLoading(true)
    let specificProject = ''
    if (values?.projectDetails || projectDetails) {
      specificProject = `<IMPORTANT>My specific project details: ${values?.projectDetails ?? projectDetails}.</IMPORTANT>`
    } else {
      specificProject = 'I have no specific project.'
    }

    const question = `${specificProject}
    <IMPORTANT>${protectedGroup}</IMPORTANT>
    `

    await getDraftsWrapper(question)
  }

  const reset = () => {
    setCurrentStep(-1)
    setFinishedSteps(0)
    setProgressException(false)
    setQuestions([])
    setPercent(0)
  }

  const retry = async () => {
    setProgressException(false)
    setPercent(0)
    setFinishedSteps(0)
    await gatherGrants()
  }

  const getAnswerWrapper = async (question: string, streaming: boolean) => {
    if (!selectedAgent || !agents) return
    await getAnswer(
      true,
      question,
      selectedAgent.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      false,
      user?.email,
      true,
      selectedConversation,
      streaming
    )
  }

  const getDraftsWrapper = async (question: string) => {
    if (!selectedAgent || !agents) return
    await getDrafts(
      true,
      question,
      selectedAgent.id,
      questions,
      agents,
      setLoading,
      setQuestions,
      t,
      setProgressException,
      setFinishedSteps,
      user?.email,
      selectedConversation
    )
  }

  if (mode === 'new') {
    return (
      <div className='flex size-full overflow-y-auto'>
        {currentStep === 0 ? (
          <CompanyInfoStep
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
          />
        ) : currentStep === 1 ? (
          <AgentResponseStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            message={questions[0]?.messages[1]?.message ?? ''}
            loading={loading}
          />
        ) : currentStep === 2 ? (
          <ProtectedGroupStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            setProtectedGroup={setProtectedGroup}
          />
        ) : currentStep === 3 ? (
          <SpecificProjectStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            gatherGrants={gatherGrants}
            setProjectDetails={setProjectDetails}
          />
        ) : currentStep === 4 ? (
          <SelectGrantStep
            reset={reset}
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
            message={questions[1]?.messages[1]?.message ?? ''}
            percent={percent}
            streamMessage={streamMessage}
            loading={loading}
            retry={retry}
            progressException={progressException}
          />
        ) : currentStep === 5 ? (
          <GrantInfoStep
            reset={reset}
            setCurrentStep={setCurrentStep}
            getAnswerWrapper={getAnswerWrapper}
            message={questions[2]?.messages[1]?.message ?? ''}
            loading={loading}
          />
        ) : currentStep === 6 ? (
          <GrantEditor
            document={questions[3]?.messages[1]?.message ?? ''}
            questions={questions}
            getAnswerWrapper={getAnswerWrapper}
            sections={sections}
            setSections={setSections}
            setCurrentStep={setCurrentStep}
          />
        ) : currentStep === 7 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            reset={reset}
            setCurrentStep={setCurrentStep}
            setMatcherOpen={setMatcherOpen}
            loading={!questions[4]?.messages[1]?.message}
          />
        ) : currentStep === 8 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  if (mode === 'existing') {
    return (
      <div className='flex size-full overflow-y-auto'>
        {currentStep === 5 ? (
          <UploadGrantStep
            setCurrentStep={setCurrentStep}
            setExistingGrantDocument={setExistingGrantDocument}
          />
        ) : currentStep === 6 ? (
          <GrantEditor
            document={existingGrantDocument}
            sections={sections}
            setSections={setSections}
            getAnswerWrapper={getAnswerWrapper}
            setCurrentStep={setCurrentStep}
            questions={questions}
          />
        ) : currentStep === 7 ? (
          <FeedbackStep
            getAnswerWrapper={getAnswerWrapper}
            reset={reset}
            setCurrentStep={setCurrentStep}
            setMatcherOpen={setMatcherOpen}
            loading={!questions[0]?.messages[1]?.message}
          />
        ) : currentStep === 8 ? (
          <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
            <Lottie
              options={{ animationData: lottieChatLoadingScreenAnimation }}
            />
          </div>
        ) : null}
      </div>
    )
  }

  return (
    <div className='flex size-full overflow-y-auto'>
      <div className='m-auto flex flex-col gap-5'>
        <div className='pointer-events-none m-auto size-full max-w-[300px] opacity-80'>
          <Lottie
            options={{ animationData: lottieChatLoadingScreenAnimation }}
          />
        </div>
        <Button
          size='large'
          className='pulsate-infinite'
          type='primary'
          onClick={() => {
            setMode('new')
            setCurrentStep(0)
          }}
        >
          I want to start a new grant
        </Button>
        <Button
          size='large'
          className='pulsate-infinite2'
          type='primary'
          onClick={() => {
            setMode('existing')
            setCurrentStep(5)
          }}
        >
          I have an existing grant I want to refine
        </Button>
      </div>
    </div>
  )
}

export default Matcher
