'use client'

import { Button, Checkbox, Form } from 'antd'
import { SetStateAction } from 'react'

interface ProtectedGroupStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  setProtectedGroup: (protectedGroup: string) => void
}

const ProtectedGroupStep: React.FC<ProtectedGroupStepProps> = ({
  reset,
  setCurrentStep,
  setProtectedGroup,
}) => {
  const [form] = Form.useForm()

  const finish = async (values: { [x: string]: string }) => {
    let groups = ''
    const statements = values.identity ?? []
    for (const statement of statements) {
      groups += `${statement}\n`
    }
    setProtectedGroup(groups)
    setCurrentStep((currentStep) => currentStep + 1)
  }

  return (
    <div className='mx-auto my-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <p>
          To help us match you with the best grants, we need to gather some
          information about you. Many grants are designed to support specific
          groups, and knowing more about you will allow us to find the
          opportunities you are eligible for. Your responses will be kept
          confidential.
        </p>
        <p>
          Please indicate if any of the following apply to you (select all that
          apply):
        </p>

        <div className='items flex flex-col gap-4'>
          <Form.Item name='identity' className='w-full'>
            <Checkbox.Group
              className='flex flex-col gap-1'
              options={[
                'I identify as a minority (e.g., racial or ethnic minority)',
                'I am a veteran',
                'I have a disability',
                'I identify as female',
                'I identify as non-binary or gender non-conforming',
                'I identify as LGBTQ+',
                'I am an immigrant or refugee',
                'I am an indigenous person',
                'I belong to another underrepresented or disadvantaged group',
                'None of the above',
              ]}
            />
          </Form.Item>
        </div>

        <p>Privacy and Confidentiality Note:</p>
        <p>
          Your responses will be used solely to match you with grants you may be
          eligible for. We take your privacy seriously and will not share your
          information with any third parties without your consent.
        </p>

        <div className='m-0 flex justify-end gap-2'>
          <Button className='flex items-center' onClick={reset}>
            Restart
          </Button>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              htmlType='submit'
              type='primary'
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default ProtectedGroupStep
