'use client'

import { RiUpload2Line } from '@remixicon/react'
import { message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'

import { configFileHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

interface UploadGrantStepProps {
  setCurrentStep: (currentStep: number) => void
  setExistingGrantDocument: (existingGrantDocument: string) => void
}

const UploadGrantStep: React.FC<UploadGrantStepProps> = ({
  setCurrentStep,
  setExistingGrantDocument,
}) => {
  const onChange = async (info: {
    file: {
      name?: string
      status?: string
      originFileObj?: Blob
      filename?: string
    }
  }) => {
    const { status, originFileObj, filename } = info.file
    if (status === 'done') {
      setCurrentStep(6)

      try {
        if (originFileObj) {
          const formDataDocx = new FormData()
          formDataDocx.append('file', originFileObj, filename)

          const res = await axios({
            url: `${API_URL}/google-doc/extract-text`,
            method: 'post',
            withCredentials: true,
            data: formDataDocx,
            ...configFileHeader,
          })

          setExistingGrantDocument(res.data.sections)
        }
      } catch (error) {
        console.error(error)
        message.error('Failed to extract text from the uploaded file')
      }
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`)
    }
  }

  return (
    <div className='mx-auto my-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Dragger
        onChange={onChange}
        maxCount={1}
        accept='.docx'
        showUploadList={false}
      >
        <div className='flex w-full flex-col items-center justify-center gap-2 p-5'>
          <RiUpload2Line />
          <p className='text-base'>
            Upload grant application you want to refine
          </p>
          <p className='text-xs'>Click or drag a file to this area to upload</p>
        </div>
      </Dragger>
    </div>
  )
}

export default UploadGrantStep
