'use client'

import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import {
  RiAddLine,
  RiArrowRightSLine,
  RiDraggable,
  RiSparklingFill,
} from '@remixicon/react'
import { Button, FloatButton, Input, message } from 'antd'
import axios from 'axios'
import { SetStateAction, useEffect, useState } from 'react'
import Lottie from 'react-lottie'

import useAuth from '@/hooks/useAuth'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'
import { markdown } from '@/utils'
import { cn } from '@/utils/clsx'

import ActionButtons from './ActionButtons'
import CollapseButton from './CollapseButton'
import lottieGeneratingDocument from '../../../../../public/lottieGeneratingDocument.json'
import lottieScanDocument from '../../../../../public/lottieScanDocument.json'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

interface GrantEditorProps {
  document?: string | { [key: string]: Section }
  questions?: IQuestion[]
  sections?: Section[]
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  setCurrentStep: (value: SetStateAction<number>) => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
}

const GrantEditor: React.FC<GrantEditorProps> = ({
  document,
  questions,
  sections,
  setSections,
  setCurrentStep,
  getAnswerWrapper,
}) => {
  const { user } = useAuth()
  const [enhancing, setEnhancing] = useState<{ [key: string]: boolean }>()
  const [editing, setEditing] = useState<{ [key: string]: boolean }>()
  const [textTitle, setTextTitle] = useState<{ [key: string]: string }>()
  const [documentUrl, setDocumentUrl] = useState<string>()
  const [generatingDocument, setGeneratingDocument] = useState<boolean>(false)
  const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>()

  useEffect(() => {
    if (document && document !== '') {
      try {
        let json: { [key: string]: Section }
        if (typeof document === 'string') {
          json = JSON.parse(
            document.replaceAll('```json', '').replaceAll('```', '')
          )
        } else {
          json = document
        }
        const newSections = Object.keys(json).map((key: string) => ({
          id: `${Math.random().toString(36)}-${Date.now()}`, // generate random id for section
          title: json[key]?.title ?? '',
          text: json[key]?.text ?? '',
          subsections:
            json[key]?.subsections.map(
              (subsection: { title: string; text: string }) => ({
                id: `sub-${Math.random().toString(36)}-${Date.now()}`, // generate random id for subsection
                title: subsection.title,
                text: subsection.text,
              })
            ) ?? [],
        }))
        setSections(newSections)
      } catch (error) {
        console.error(error)
        message.error('Failed to parse the document')
        setCurrentStep(0)
      }
    }
  }, [document])

  const reorder = (list: Section[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const reorderSubsections = (
    list: Subsection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    if (removed) {
      result.splice(endIndex, 0, removed)
    }
    return result
  }

  const onDragEnd = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    const reorderedSections = reorder(
      sections,
      result.source.index,
      result.destination.index
    )

    setSections(reorderedSections)
  }

  const onDragEndSubsections = (result: {
    destination: { index: number } | null
    source: { index: number }
  }) => {
    if (!result.destination || !sections) {
      return
    }

    const reorderedSections = sections.map((section) => {
      if (section.subsections) {
        const reorderedSubsections = reorderSubsections(
          section.subsections,
          result.source.index,
          result.destination?.index ?? 0
        )
        return {
          ...section,
          subsections: reorderedSubsections,
        }
      }
      return section
    })

    setSections(reorderedSections)
  }

  const addSection = () => {
    if (!sections) {
      return
    }
    const id = `${Math.random().toString(36)}-${Date.now()}`
    setSections([
      ...sections,
      {
        id,
        title: '',
        text: '',
        subsections: [],
      },
    ])
    setEditing({ ...editing, [id]: true })
  }

  const generateDocument = async () => {
    setGeneratingDocument(true)
    const res = await axios(`${API_URL}/google-doc/generate-grant`, {
      method: 'post',
      ...configHeader,
      data: JSON.stringify({
        sections: sections,
        user: user?.email,
      }),
    })
    setDocumentUrl(res.data.url)
  }

  const finish = async () => {
    setCurrentStep((currentStep) => currentStep + 1)
    await getAnswerWrapper(
      `Final document by sections: ${sections
        ?.map(
          (s) =>
            `${s.title}\n${s.text}\n${s.subsections.map((sub) => `${sub.title}\n${sub.text}\n`).join('\n')}`
        )
        .join('\n')}`,
      false
    )
  }

  if (documentUrl) {
    return (
      <div className='m-auto size-full sm:max-w-[100em]'>
        <iframe src={documentUrl} className='m-auto size-full max-w-[100rem]' />
        <FloatButton
          icon={<RiArrowRightSLine className='size-5' />}
          className='flex items-center'
          type='primary'
          onClick={finish}
          tooltip={<div>Finish</div>}
        />
      </div>
    )
  }

  if (!document || document === '') {
    return (
      <div className='pointer-events-none m-auto h-full max-w-[500px]'>
        <Lottie options={{ animationData: lottieScanDocument }} />
      </div>
    )
  }

  if (generatingDocument) {
    return (
      <div className='pointer-events-none m-auto h-full max-w-[500px]'>
        <Lottie options={{ animationData: lottieGeneratingDocument }} />
      </div>
    )
  }

  return (
    <div className='m-auto flex w-full flex-col gap-4 p-3 sm:max-w-[100em]'>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='flex flex-col gap-3'
            >
              {sections?.map((section, index: number) => (
                <Draggable
                  key={section.id}
                  draggableId={section.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={cn(
                        'flex gap-4 rounded-lg bg-surface px-3 py-2 text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
                        enhancing?.[section.id] &&
                          'opacity-50 pointer-events-none',
                        snapshot.isDragging &&
                          'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
                      )}
                    >
                      <div {...provided.dragHandleProps}>
                        <RiDraggable className='mt-1 size-5' />
                      </div>
                      <div className='flex w-full flex-col gap-3 text-left'>
                        <div className='flex items-center gap-2'>
                          {editing?.[section.id] ? (
                            <Input
                              value={textTitle?.[section.id]}
                              onChange={(event) =>
                                setTextTitle({
                                  ...textTitle,
                                  [section.id]: event.target.value,
                                })
                              }
                            />
                          ) : (
                            <div className='flex w-full items-center justify-between'>
                              <h2 className='text-xl font-bold'>
                                {section.title}
                              </h2>
                              <CollapseButton
                                section={section}
                                setCollapsed={setCollapsed}
                                isCollapsed={collapsed?.[section.id]}
                              />
                            </div>
                          )}
                        </div>

                        {editing?.[section.id] ? (
                          <Input.TextArea
                            autoSize={{ minRows: 5 }}
                            value={section.text}
                            className='w-full'
                            onChange={(event) => {
                              const newSections = sections?.map((s) => {
                                if (s.id === section.id) {
                                  return {
                                    ...s,
                                    text: event.target.value,
                                  }
                                }
                                return s
                              })
                              setSections(newSections)
                            }}
                          />
                        ) : (
                          <>
                            {!collapsed?.[section.id] && (
                              <div className='markdown-answer break-words text-sm'>
                                {markdown(section.text)}
                              </div>
                            )}
                          </>
                        )}
                        <ActionButtons
                          section={section}
                          setSections={setSections}
                          sections={sections}
                          questions={questions}
                          enhancing={enhancing}
                          setEnhancing={setEnhancing}
                          setEditing={setEditing}
                          textTitle={textTitle}
                          setTextTitle={setTextTitle}
                          isEditing={editing?.[section.id]}
                        />
                        <DragDropContext onDragEnd={onDragEndSubsections}>
                          <Droppable
                            droppableId={`${section.id}-subsectionsDroppable`}
                          >
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className='flex flex-col gap-2'
                              >
                                {section.subsections?.map(
                                  (subsection, index: number) => (
                                    <Draggable
                                      key={subsection.id}
                                      draggableId={subsection.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          className={cn(
                                            'flex gap-2 rounded-lg bg-surface px-3 py-2 text-left dark:bg-dark-surface text-on-surface dark:text-dark-on-surface',
                                            enhancing?.[subsection.id] &&
                                              'opacity-50 pointer-events-none',
                                            snapshot.isDragging &&
                                              'bg-primary dark:bg-dark-primary text-on-primary dark:text-dark-on-primary'
                                          )}
                                        >
                                          <div {...provided.dragHandleProps}>
                                            <RiDraggable className='mt-1 h-5 w-fit' />
                                          </div>
                                          <div className='flex w-full flex-col gap-2'>
                                            <div className='flex items-center gap-2'>
                                              {editing?.[subsection.id] ? (
                                                <Input
                                                  value={
                                                    textTitle?.[subsection.id]
                                                  }
                                                  onChange={(event) =>
                                                    setTextTitle({
                                                      ...textTitle,
                                                      [subsection.id]:
                                                        event.target.value,
                                                    })
                                                  }
                                                />
                                              ) : (
                                                <div className='flex w-full items-center justify-between'>
                                                  <h2 className='text-lg font-semibold'>
                                                    {subsection.title}
                                                  </h2>
                                                  <CollapseButton
                                                    section={subsection}
                                                    setCollapsed={setCollapsed}
                                                    isCollapsed={
                                                      collapsed?.[subsection.id]
                                                    }
                                                  />
                                                </div>
                                              )}
                                            </div>
                                            {editing?.[subsection.id] ? (
                                              <Input.TextArea
                                                autoSize={{ minRows: 5 }}
                                                value={subsection.text}
                                                className='w-full'
                                                onChange={(event) => {
                                                  const newSections =
                                                    sections?.map((s) => {
                                                      const newSubsections =
                                                        s.subsections.map(
                                                          (sub) => {
                                                            if (
                                                              sub.id ===
                                                              subsection.id
                                                            ) {
                                                              return {
                                                                ...sub,
                                                                text: event
                                                                  .target.value,
                                                              }
                                                            }
                                                            return sub
                                                          }
                                                        )
                                                      return {
                                                        ...s,
                                                        subsections:
                                                          newSubsections,
                                                      }
                                                    })
                                                  setSections(newSections)
                                                }}
                                              />
                                            ) : (
                                              <>
                                                {!collapsed?.[
                                                  subsection.id
                                                ] && (
                                                  <div className='markdown-answer break-words text-sm'>
                                                    {markdown(subsection.text)}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                            <ActionButtons
                                              section={subsection}
                                              setSections={setSections}
                                              sections={sections}
                                              questions={questions}
                                              enhancing={enhancing}
                                              setEnhancing={setEnhancing}
                                              setEditing={setEditing}
                                              textTitle={textTitle}
                                              setTextTitle={setTextTitle}
                                              isEditing={
                                                editing?.[subsection.id]
                                              }
                                              isSubsection
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className='flex justify-between gap-2'>
        <Button
          className='w-fit self-end'
          icon={<RiAddLine className='size-5' />}
          onClick={addSection}
        >
          Add Section
        </Button>
        <Button
          className='w-fit self-end'
          icon={<RiSparklingFill className='size-5' />}
          type='primary'
          onClick={generateDocument}
        >
          Generate Grant Application
        </Button>
      </div>
    </div>
  )
}

export default GrantEditor
