'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { SetStateAction } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import useAgents from '@/hooks/useAgents'
import useAuth from '@/hooks/useAuth'

import { getAnswerDraft } from '@/service/Chatbot'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

interface EnhanceButtonProps {
  section: Section | Subsection
  isSubsection?: boolean
  enhancing?: { [key: string]: boolean }
  setEnhancing: (
    value: SetStateAction<
      | {
          [key: string]: boolean
        }
      | undefined
    >
  ) => void
  sections?: Section[]
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  questions?: IQuestion[]
  className?: string
}

const EnhanceButton: React.FC<EnhanceButtonProps> = ({
  section,
  isSubsection,
  enhancing,
  setEnhancing,
  sections,
  setSections,
  questions,
  className,
}) => {
  const { user } = useAuth()
  const { selectedAgent } = useAgents()
  const { selectedConversation } = useDrawerState()

  const draftAnswer = (question: string, questions: IQuestion[]) => {
    return getAnswerDraft(
      true,
      question,
      1,
      questions,
      selectedAgent.id,
      undefined,
      true,
      false,
      user?.email,
      selectedConversation
    )
  }

  const createTmpMessage = (question: string): IQuestion => {
    return {
      question: question,
      messages: [
        {
          role: 'user',
          type: 'text',
          domain: true,
          message: question,
          agent: selectedAgent.id,
          drafts: [],
          documents: [],
          titles: [],
          googleDriveUrls: [],
          timestamp: dayjs().format('HH:mm / DD.MM.YYYY'),
          isCommand: false,
          feedbackScore: 0,
        },
      ],
    }
  }

  const enhance = async () => {
    setEnhancing((prev) => ({ ...prev, [section.id]: true }))
    const question = `
      Here are all sections from the document for context: 

      ${sections?.map((s) => JSON.stringify(s)).join('\n')}
        
      Enhance this section of the grant and make it 4 times longer, return only the generated text in markdown:
  
      ${JSON.stringify(section)}
  
      You must respond in a valid json format and no other text, with structure: 
      {
      "title": "1st section title",
      "text": "1st section text in markdown",
      "subsections": [{
          title: "1st subsection title",
          text: "1st subsection text in markdown",
      }, ...]
      }
      SECTION CAN HAVE NONE OR MULTIPLE SUBSECTIONS.
      DO NOT USE DOUBLE PARENTHESES.
      DO NOT USE BACKSLASHES.
      `
    const tmpQuestion: IQuestion = createTmpMessage(question)
    const res: { answer: string } = await draftAnswer(question, [
      ...(questions ?? []),
      tmpQuestion,
    ])
    try {
      const json = JSON.parse(
        res.answer.replaceAll('```json', '').replaceAll('```', '')
      )
      setSections((prevSections) => {
        return prevSections?.map((s) => {
          if (s.id === section.id) {
            return {
              id: s.id,
              title: json.title,
              text: json.text,
              subsections: json.subsections.map(
                (subsection: { title: string; text: string }) => ({
                  id: `sub-${Math.random().toString(36)}-${Date.now()}`, // generate random id for subsection
                  title: subsection.title,
                  text: subsection.text,
                })
              ),
            }
          }
          return s
        })
      })
    } catch (error) {
      message.error('Error enhancing section')
      console.error(error)
    }
    setEnhancing((prev) => ({ ...prev, [section.id]: false }))
  }

  const enhanceSubsection = async () => {
    setEnhancing((prev) => ({ ...prev, [section.id]: true }))
    const question = `
      Here are all sections from the document for context: 

      ${sections?.map((s) => JSON.stringify(s)).join('\n')}
      
      Enhance this subsection of the grant and make it 4 times longer, return only the generated text in markdown:
      
      ${JSON.stringify(section)}
  
      You must respond in a valid json format and no other text, with structure: 
      {
      "title": "1st section title",
      "text": "1st section text in markdown",
      }
      DO NOT USE DOUBLE PARENTHESES.
      DO NOT USE BACKSLASHES.
      `
    const tmpQuestion: IQuestion = createTmpMessage(question)
    const res: { answer: string } = await draftAnswer(question, [
      ...(questions ?? []),
      tmpQuestion,
    ])
    try {
      const json = JSON.parse(
        res.answer.replaceAll('```json', '').replaceAll('```', '')
      )
      setSections((prevSections) => {
        return prevSections?.map((s) => {
          const newSubsections = s.subsections.map((sub) => {
            if (sub.id === section.id) {
              return {
                id: sub.id,
                title: json.title,
                text: json.text,
              }
            }
            return sub
          })
          return {
            ...s,
            subsections: newSubsections,
          }
        })
      })
    } catch (error) {
      console.error(error)
    }
    setEnhancing((prev) => ({ ...prev, [section.id]: false }))
  }

  return (
    <Tooltip title='Enhance'>
      <Button
        className='w-fit'
        icon={<RiSparklingFill className={className} />}
        loading={enhancing?.[section.id]}
        onClick={isSubsection ? enhanceSubsection : enhance}
        size='small'
        type='text'
      >
        {enhancing?.[section.id] ? 'Enhancing...' : ''}
      </Button>
    </Tooltip>
  )
}

export default EnhanceButton
