'use client'

import { RiSparklingFill } from '@remixicon/react'
import { Button, Form, Input } from 'antd'
import { SetStateAction } from 'react'

interface SpecificProjectStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  setProjectDetails: (projectDetails?: string) => void
  gatherGrants: (values?: { [x: string]: string }) => Promise<void>
}

const SpecificProjectStep: React.FC<SpecificProjectStepProps> = ({
  reset,
  setCurrentStep,
  setProjectDetails,
  gatherGrants,
}) => {
  const [form] = Form.useForm()

  const finish = async (values: { [x: string]: string }) => {
    setCurrentStep((currentStep) => currentStep + 1)
    setProjectDetails(values.projectDetails)
    await gatherGrants(values)
  }

  return (
    <div className='mx-auto my-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          <div className='items flex flex-col gap-4'>
            <div>
              Do you have a specific project for which you are seeking funding,
              or other details related to your initiatives, that you would like
              to share? Please include a project budget, estimated project
              timeline, or any prior grants you have received or applied for.
              You can also share other relevant documents like market studies to
              help us better understand your needs.
            </div>
            <Form.Item
              name='projectDetails'
              rules={[{ type: 'string' }]}
              className='w-full'
            >
              <Input.TextArea
                placeholder='Information about any specific project for which you are seeking funding'
                rows={5}
              />
            </Form.Item>
          </div>

          <div className='m-0 flex justify-end gap-2'>
            <Button className='flex items-center' onClick={reset}>
              Restart
            </Button>
            <Form.Item className='m-0'>
              <Button
                className='flex items-center'
                icon={<RiSparklingFill className='size-5' />}
                htmlType='submit'
                type='primary'
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default SpecificProjectStep
