'use client'

import { RiSparklingFill, RiUpload2Line } from '@remixicon/react'
import { Button, Form, Input } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAgents from '@/hooks/useAgents'

import { uploadDocuments } from '@/service/Chatbot'
import { convertToFileList } from '@/utils/upload'

const instruction = `Research my company with the search and scrape function and context and list in detail the information you learned about my company. Present me with a summary of the information gathered.`

interface CompanyInfoStepProps {
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  setCurrentStep: (value: SetStateAction<number>) => void
}

const CompanyInfoStep: React.FC<CompanyInfoStepProps> = ({
  getAnswerWrapper,
  setCurrentStep,
}) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [fileList, setFileList] = useState<FileList | null>(null)
  const { selectedAgentAdmin } = useAgents()
  const [uploading, setUploading] = useState(false)

  const finish = async (values: { [x: string]: string }) => {
    if (fileList) {
      setUploading(true)
      await uploadDocuments(fileList, selectedAgentAdmin.id, t, setFileList)
      setUploading(false)
    }
    setCurrentStep((currentStep) => currentStep + 1)
    const newContext = `Company URL: ${values.companyUrl}, Address: ${values.address}, Number of employees: ${values.numberOfEmployees}, Annual revenue: ${values.annualRevenue}.`
    await getAnswerWrapper(`${newContext}\n ${instruction}`, true)
  }

  return (
    <div className='mx-auto my-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-4 text-left'
        labelAlign='left'
        labelCol={{ span: 8 }}
      >
        <p>
          To help us match you with the best grants, we need to gather some
          information about you. You should provide at least the URL of the
          company for which you would like to find funding. The more information
          you provide, the greater your chances of matching to an ideal grant.
          Your responses will be kept confidential.
        </p>

        <Form.Item
          label='Company URL'
          name='companyUrl'
          rules={[{ type: 'string', required: true }]}
        >
          <Input placeholder='Company URL' />
        </Form.Item>
        <Form.Item
          label={
            <h4>
              Address <span className='opacity-60'>(optional)</span>
            </h4>
          }
          name='address'
          rules={[{ type: 'string' }]}
        >
          <Input placeholder='Address' />
        </Form.Item>
        <Form.Item
          label={
            <h4>
              Number of employees <span className='opacity-60'>(optional)</span>
            </h4>
          }
          name='numberOfEmployees'
          rules={[{ type: 'string' }]}
        >
          <Input placeholder='Number of employees' />
        </Form.Item>
        <Form.Item
          label={
            <h4>
              Annual revenue <span className='opacity-60'>(optional)</span>
            </h4>
          }
          name='annualRevenue'
          rules={[{ type: 'string' }]}
        >
          <Input placeholder='Annual revenue' />
        </Form.Item>

        <Dragger
          multiple
          onChange={(e) => {
            setFileList(convertToFileList(e.fileList))
          }}
        >
          <div className='flex w-full flex-col items-center justify-center gap-2 p-5'>
            <RiUpload2Line />
            <p className='text-base'>
              Upload company information files{' '}
              <span className='opacity-60'>(optional)</span>
            </p>
            <p className='text-xs'>
              Click or drag files to this area to upload
            </p>
          </div>
        </Dragger>

        <div className='m-0 flex justify-end gap-2'>
          <Form.Item className='m-0'>
            <Button
              className='flex items-center'
              icon={<RiSparklingFill className='size-5' />}
              htmlType='submit'
              type='primary'
              disabled={uploading}
            >
              {uploading ? 'Importing' : 'Next'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default CompanyInfoStep
