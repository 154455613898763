'use client'

import { Button, Form, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction } from 'react'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'

import { markdown } from '@/utils'

import lottieSearchingAnimation from '../../../../../public/lottieSearching.json'

interface AgentResponseStepProps {
  reset: () => void
  setCurrentStep: (value: SetStateAction<number>) => void
  message: string
  loading?: boolean
}

const AgentResponseStep: React.FC<AgentResponseStepProps> = ({
  reset,
  setCurrentStep,
  message,
  loading,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()

  const finish = () => {
    setCurrentStep((currentStep) => currentStep + 1)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={finish}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {message ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              {markdown(message)}
              <div id='anchor' className='opacity-0'>
                a
              </div>
            </div>
          ) : (
            <div className='pointer-events-none m-auto h-full max-w-[500px]'>
              <Lottie options={{ animationData: lottieSearchingAnimation }} />
            </div>
          )}

          {!loading ? (
            <div className='m-0 flex justify-end gap-2'>
              <Button
                className='flex items-center'
                disabled={loading}
                onClick={reset}
              >
                Restart
              </Button>
              <Form.Item className='m-0'>
                <Button
                  className='flex items-center'
                  htmlType='submit'
                  disabled={loading}
                  type='primary'
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={`data:image/png;base64, ${selectedAgent.avatar}`}
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={16}
          height={16}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default AgentResponseStep
