'use client'

import {
  RiAddLine,
  RiCheckLine,
  RiDeleteBinLine,
  RiPencilLine,
} from '@remixicon/react'
import { Button, Popconfirm, Tooltip } from 'antd'
import { SetStateAction } from 'react'

import EnhanceButton from './EnhanceButton'

import { IQuestion } from '@/types/chatbot'
import { Section, Subsection } from '@/types/document'

interface ActionButtonsProps {
  section: Section | Subsection
  isSubsection?: boolean
  isEditing?: boolean
  questions?: IQuestion[]
  sections?: Section[]
  setSections: (value: SetStateAction<Section[] | undefined>) => void
  enhancing?: { [key: string]: boolean }
  setEnhancing: (
    value: SetStateAction<
      | {
          [key: string]: boolean
        }
      | undefined
    >
  ) => void
  setEditing: (
    value: SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
  textTitle?: { [key: string]: string }
  setTextTitle: (textTitle: { [key: string]: string }) => void
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
  section,
  isSubsection,
  isEditing,
  questions,
  sections,
  setSections,
  enhancing,
  setEnhancing,
  setEditing,
  textTitle,
  setTextTitle,
}) => {
  const deleteSection = (id: string) => () => {
    const newSections = sections?.filter((section) => section.id !== id)
    setSections(newSections)
  }

  const deleteSubsection = (id: string) => () => {
    const newSections = sections?.map((s) => {
      const newSubsections = s.subsections.filter(
        (subsection) => subsection.id !== id
      )
      return {
        ...s,
        subsections: newSubsections,
      }
    })
    setSections(newSections)
  }

  const toggleEditSection = (id: string, title: string) => () => {
    setTextTitle({ ...textTitle, [id]: title })
    if (isEditing) {
      const newSections = sections?.map((s) => {
        if (s.id === id) {
          return {
            ...s,
            title: textTitle?.[id] ?? s.title,
          }
        }
        return s
      })
      setSections(newSections)
      setTextTitle({ ...textTitle, [id]: '' })
    }
    setEditing((editing) => ({ ...editing, [id]: !isEditing }))
  }

  const toggleEditSubsection = (id: string, title: string) => () => {
    setTextTitle({ ...textTitle, [id]: title })
    if (isEditing) {
      const newSections = sections?.map((s) => {
        const newSubsections = s.subsections.map((sub) => {
          if (sub.id === id) {
            return {
              ...sub,
              title: textTitle?.[id] ?? sub.title,
            }
          }
          return sub
        })
        return {
          ...s,
          subsections: newSubsections,
        }
      })
      setSections(newSections)
      setTextTitle({ ...textTitle, [id]: '' })
    }
    setEditing((editing) => ({ ...editing, [id]: !isEditing }))
  }

  const addSubsection = (sectionId: string) => {
    if (!sections) {
      return
    }
    const id = `${Math.random().toString(36)}-${Date.now()}`
    const subsection = {
      id,
      title: '',
      text: '',
    }
    setSections(
      sections.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            subsections: [...section.subsections, subsection],
          }
        }
        return section
      })
    )
    setEditing((editing) => ({ ...editing, [id]: true }))
  }

  return (
    <div className='flex gap-1'>
      {isEditing ? (
        <>
          <Button
            icon={<RiCheckLine className='size-4' />}
            onClick={
              isSubsection
                ? toggleEditSubsection(section.id, section.title)
                : toggleEditSection(section.id, section.title)
            }
            size='small'
            type='text'
          />
          <Popconfirm
            trigger='click'
            title='Are you sure you want to delete this section?'
            okType='danger'
            okText='Yes'
            cancelText='No'
            onConfirm={
              isSubsection
                ? deleteSubsection(section.id)
                : deleteSection(section.id)
            }
          >
            <Button
              icon={<RiDeleteBinLine className='size-4' />}
              size='small'
              type='text'
            />
          </Popconfirm>
        </>
      ) : (
        <>
          <EnhanceButton
            section={section}
            enhancing={enhancing}
            questions={questions}
            setEnhancing={setEnhancing}
            setSections={setSections}
            sections={sections}
            className='size-4'
            isSubsection={isSubsection}
          />
          <Tooltip title='Edit'>
            <Button
              icon={<RiPencilLine className='size-4' />}
              onClick={
                isSubsection
                  ? toggleEditSubsection(section.id, section.title)
                  : toggleEditSection(section.id, section.title)
              }
              size='small'
              type='text'
            />
          </Tooltip>
          {!isSubsection && (
            <Tooltip title='Add subsection'>
              <Button
                icon={<RiAddLine className='size-4' />}
                onClick={() => addSubsection(section.id)}
                size='small'
                type='text'
              />
            </Tooltip>
          )}
          <Tooltip title='Delete'>
            <Popconfirm
              trigger='click'
              title={`Are you sure you want to delete this ${isSubsection ? 'subsection' : 'section'}?`}
              okType='danger'
              okText='Yes'
              cancelText='No'
              onConfirm={
                isSubsection
                  ? deleteSubsection(section.id)
                  : deleteSection(section.id)
              }
            >
              <Button
                icon={<RiDeleteBinLine className='size-4' />}
                size='small'
                type='text'
              />
            </Popconfirm>
          </Tooltip>
        </>
      )}
    </div>
  )
}

export default ActionButtons
