'use client'

import { RiEyeLine, RiEyeOffLine } from '@remixicon/react'
import { Button } from 'antd'
import { SetStateAction } from 'react'

import { Section, Subsection } from '@/types/document'

interface CollapseButtonProps {
  section: Section | Subsection
  isCollapsed?: boolean
  setCollapsed: (
    value: SetStateAction<{ [key: string]: boolean } | undefined>
  ) => void
}

const CollapseButton: React.FC<CollapseButtonProps> = ({
  section,
  isCollapsed,
  setCollapsed,
}) => {
  const toggleCollapse = () => () => {
    setCollapsed((collapsed) => ({ ...collapsed, [section.id]: !isCollapsed }))
  }

  return (
    <Button
      icon={
        isCollapsed ? (
          <RiEyeOffLine className='size-4' />
        ) : (
          <RiEyeLine className='size-4' />
        )
      }
      onClick={toggleCollapse()}
      size='small'
      type='text'
    />
  )
}

export default CollapseButton
