'use client'

import { Button, Form, Input, Progress, Skeleton } from 'antd'
import Image from 'next/image'
import { SetStateAction } from 'react'
import Lottie from 'react-lottie'

import useAgents from '@/hooks/useAgents'

import { markdown } from '@/utils'

import lottieGrantsAnimation from '../../../../../public/lottieGrants.json'

interface SelectGrantStepProps {
  reset: () => void
  getAnswerWrapper: (question: string, streaming: boolean) => Promise<void>
  setCurrentStep: (value: SetStateAction<number>) => void
  message: string
  percent: number
  progressException: boolean
  streamMessage?: boolean
  loading?: boolean
  retry: () => void
}

const SelectGrantStep: React.FC<SelectGrantStepProps> = ({
  reset,
  getAnswerWrapper,
  setCurrentStep,
  message,
  percent,
  streamMessage,
  loading,
  progressException,
  retry,
}) => {
  const [form] = Form.useForm()
  const { selectedAgent } = useAgents()

  const next = async (values: { [x: string]: string }) => {
    setCurrentStep((currentStep) => currentStep + 1)

    let newContext = values.selectedGrant
      ? `User selected grant: ${values.selectedGrant}`
      : 'Select one grant that most closely aligns with my company.'
    newContext += `\n Provide the real URL for the selected grant. Then list out the criteria for successfully applying to that grant and how my company specifically meets each of these criteria being extremely specific including facts and data both about the grant and the company. List out the typical grant award size for companies based on available data on the web, and include the deadline for applying. Use citations. `
    await getAnswerWrapper(`${newContext}`, true)
  }

  return (
    <div className='relative mx-auto mb-8 mt-4 h-fit w-full rounded-lg bg-surface p-6 text-on-surface sm:max-w-[60em] dark:bg-dark-surface dark:text-dark-on-surface'>
      <Form
        form={form}
        onFinish={next}
        autoComplete='off'
        className='flex flex-col gap-6 text-left'
        labelAlign='left'
      >
        <div className='flex flex-col gap-6'>
          {message && streamMessage ? (
            <div
              id='scroller'
              className='markdown-answer break-words text-left text-sm sm:text-base'
            >
              {markdown(message)}
              <div id='anchor' className='opacity-0'>
                a
              </div>
              <div className='pt-2'>
                Select one of the grants listed above which aligns most closely
                with your company or let the AI select the best grant for you.
              </div>
              <Form.Item
                name='selectedGrant'
                rules={[{ type: 'string' }]}
                className='w-full pt-2'
              >
                <Input placeholder='Select one grant from the list' />
              </Form.Item>
            </div>
          ) : (
            <div className='m-auto flex flex-col items-center gap-1'>
              <div className='pointer-events-none h-full max-w-[500px]'>
                <Lottie options={{ animationData: lottieGrantsAnimation }} />
              </div>
              <Progress
                percent={percent}
                showInfo={false}
                status={progressException ? 'exception' : 'active'}
              />
              {progressException ? (
                <>
                  <p>
                    Error occurred while trying to fetch all available grants.
                  </p>
                  <Button className='mt-2' type='default' onClick={retry}>
                    Retry
                  </Button>
                </>
              ) : (
                <p>Finding the best grants can take a few moments.</p>
              )}
            </div>
          )}

          {!loading && !progressException ? (
            <div className='m-0 flex justify-end gap-2'>
              <Button className='flex items-center' onClick={reset}>
                Finish
              </Button>
              <Form.Item className='m-0'>
                <Button
                  className='flex items-center'
                  htmlType='submit'
                  type='primary'
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          ) : (
            <div className='h-10' />
          )}
        </div>
      </Form>
      {selectedAgent ? (
        <Image
          src={`data:image/png;base64, ${selectedAgent.avatar}`}
          alt='Chatbot avatar'
          className='absolute bottom-[-12px] left-4 size-16 rounded-md shadow-md'
          width={16}
          height={16}
        />
      ) : (
        <Skeleton.Image
          active={true}
          className='absolute bottom-[-12px] left-4 size-16 rounded-md p-[3px]'
        />
      )}
    </div>
  )
}

export default SelectGrantStep
